<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <v-btn icon class="mr-2" @click="$router.push({ name: 'E-Test' })">
        <v-icon large>mdi-arrow-left</v-icon>
      </v-btn>
      Daftar Nilai
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="listData"
        :loading="loadingData"
        item-key="id"
        class="elevation-0"
      >
        <template v-slot:top>
          <v-row class="px-4">
            <v-col cols="12" sm="9">
              Mata Pelajaran: {{ subject }}<br />Judul: {{ title }}
            </v-col>
            <v-col cols="12" sm="3">
              <v-select
                v-model="modelClassroom"
                :items="listClassroom"
                :loading="loadingClassroom"
                dense
                item-text="name"
                item-value="id"
                label="Kelas"
                class="select-175 mr-2"
                outlined
                clearable
                @change="getData()"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-5" />
        </template>
        <template #item.action="{ item }">
          <v-btn
            class="blue mr-2"
            depressed
            icon
            dark
            small
            @click="action(item)"
          >
            <v-icon small>mdi-eye</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { classroom } from "@/api/master";
import { etestParticipants } from "@/api/etest";

export default {
  created() {
    this.getClassroom();
    this.getData();
  },
  watch: {
    options: {
      handler(param) {
        this.query.page = param.page;
        this.query.limit = param.itemsPerPage;

        this.getData();
      },
      deep: true,
    },
  },
  data() {
    return {
      modelClassroom: null,
      loadingClassroom: false,
      loadingData: false,
      listClassroom: [],
      listData: [],
      subject: "",
      title: "",
      headers: [
        { text: "Nama Siswa", value: "name" },
        { text: "Nilai (Poin)", value: "score" },
        { text: "Aksi", value: "action", align: "center", sortable: false },
      ],
    };
  },
  methods: {
    action(item) {
      this.$router.push({
        name: "CorrectionEtest",
        query: { etest: item.e_test, person: item.person },
      });
    },
    getClassroom() {
      this.loadingClassroom = true;
      classroom()
        .then((res) => {
          if (res.data.code) {
            this.listClassroom = res.data.data;
          }
        })
        .finally(() => (this.loadingClassroom = false));
    },
    getData() {
      this.loadingData = true;
      etestParticipants({
        id: this.$route.query.id,
        classroom: this.modelClassroom,
      })
        .then((res) => {
          if (res.data.code) {
            const result = res.data.data;
            this.listData = result.data;
            this.subject = result.header.subject;
            this.title = result.header.title;
          }
        })
        .finally(() => (this.loadingData = false));
    },
  },
};
</script>
